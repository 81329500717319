import { useMutation } from "@vue/apollo-composable";
import gql from "graphql-tag";
import { pick, isObject, first, memoize, has } from "lodash";
import { defineRule } from "vee-validate";
import { debounce } from "perfect-debounce";

type ValidationResult = {
  valid: boolean;
  errors: string[];
};

type ValidationVariables = {
  value: any;
  rules: any;
};

export const useRemoteValidate = () => {
  const { loading, mutate } = useMutation<
    { validate: ValidationResult },
    ValidationVariables
  >(gql`
    mutation ($value: Mixed!, $rules: Mixed!) {
      validate(value: $value, rules: $rules) {
        valid
        errors
      }
    }
  `);

  const validate = async (value: any, rules: any) => {
    if (isObject(rules)) {
      if (!isObject(value)) {
        throw new Error("Object rules require an object value");
      }
    }

    const r = await mutate({ value, rules }).then((data) =>
      Promise.resolve(pick(data?.data?.validate, ["valid", "errors"]))
    );

    return r;
  };

  const createValidator = (rules: any, errorMessage?: string) => {
    // We use a combination of memoization and async debounce to
    // counter the effects of infinite calls when access the `loading` ref
    const fn = memoize(async function (value: any) {
      if (!value) {
        return true;
      }

      const result = await validate(value, rules);

      if (result?.valid) {
        return true;
      }

      return errorMessage || first(result?.errors) || "Invalid input";
    });

    return debounce(fn, 500, { leading: false, trailing: true });
  };

  const defineValidator = (name: string, rules: any, errorMessage?: string) => {
    const validator = createValidator(rules, errorMessage);
    defineRule(name, validator);
  };

  return {
    loading,
    validate,
    createValidator,
    defineValidator,
  };
};
